<template>
  <div style="height:100%">
    <dv-border-box-12 style="height:60%">
      <!-- <div style="height:60%"> -->
      <div id="leftLevel">
        <div class="bg-color-black">
          <div class="d-flex pt-2 pl-2" style="height:5%;">
            <span style="color: #5cd9e8;" class="title-icon">
              <icon name="chart-bar"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">安置房情况</span>
            </div>

          </div>
          <div id="parent" style="height: 20%" class="top_title">
            <div class="contact_top" style="width: 100%">
              <div class="Settled">
                <p class="num">总住宅数<span>{{totalData.plantArea+totalData.arrangeArea + totalData.allotCount +totalData.borrowCount + totalData.otherCount + totalData.blueCount | changeNum}}</span>套</p>
              </div>
            </div>
            <div class="contact_top" style="width: 100%">
              <div class="Settled">
                <p class="num">已安置<span>{{totalData.plantArea | changeNum}}</span>套</p>
              </div>
            </div>
          </div>
          <div id="parent" style="height: 20%" class="top_title">
            <div class="contact_top" style="width: 100%">
              <div class="Settled">
                <p class="num">未安置<span>{{totalData.arrangeArea + totalData.allotCount +totalData.borrowCount + totalData.otherCount + totalData.blueCount | changeNum}}</span>套</p>
              </div>
            </div>
            <div class="contact_top" style="width: 100%">
              <div class="Settled">
                <p class="num">安置率<span>{{((this.totalData.plantNum/this.totalData.allTotal)*100).toFixed()+'%'|| '暂无信息'}}</span></p>
              </div>
            </div>
          </div>
           <div class="d-flex pt-2 pl-2">
            <span style="color: #5cd9e8;" class="title-icon">
              <icon name="chart-pie"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">未安置情况占比</span>
            </div>
          </div>
          <div style="height: 50%" class="myChart">
              <Company :company='company' />
            <!-- <div id="myChart"></div> -->
            <!-- <div class="myChartflex" style="width:40%">
              <div class="Settled">
                <p class="num">已安置<span>{{totalData.plantArea | changeNum}}</span>套</p>
              </div>
              <div class="chart_Rehousingrate" id="chArt"></div>
            </div> -->
            <div class="myChartflex" style="width:100%">
              <!-- <div class="Settled">
                <p class="num">未安置<span>{{totalData.arrangeArea + totalData.allotCount +totalData.borrowCount + totalData.otherCount + totalData.blueCount | changeNum}}</span>套</p>
              </div> -->
              <div id="myChart"></div>
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-12>
    <!-- </div> -->
    <!-- <div  style="height:40%"> -->
    <dv-border-box-12 style="height:40%">
      <div id="leftLoops">
        <div class="bg-color-black">
          <div class="d-flex pt-2 pl-2">
            <span style="color: #5cd9e8;" class="title-icon">
              <icon name="chart-pie"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">未安置房源小区占比</span>
            </div>
          </div>
          <!-- <dv-decoration-2 style="width:200px;height:5px;" /> -->
          <div class="histogram-info">
             <Company :company='company' />
            <div id="myCharts"></div>
            <p class="empty" v-if='emptyText'>暂无信息</p>
          </div>
        </div>
      </div>
    </dv-border-box-12>
    <!-- </div> -->
  </div>
</template>

<script>
import Company from '../Company'
import arrangeApi from '@/xhr/arrange'
import { changeNum } from '@/filters/thousand'
export default {
  name: "leftLevel",
  filters: {
    changeNum
  },
  data () {
    return {
       company: '套',
      totalData: [],
      timer: null,
      emptyText: false,
    };
  },
   components: {
    Company
  },
  created () {
    this.getData()
  },
  mounted () {
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getData()
      }, 0)
    }, 60000)

  },
  destroyed () {
    //  销毁定时器
    window.clearInterval(this.timer)
  },
  methods: {
    getData () {
      arrangeApi.getLeftData().then(res => {
        if (res.data.code === '200') {
          this.totalData = res.data.data
          // 处理小区占比
          this.totalData.areaMap.sort((a, b) => {
            return b.value - a.value
          })
          if (this.totalData.areaMap.length === 0) {
            this.emptyText = true
          } else {
            this.emptyText = false
          }
          let maxArr = []
          if (this.totalData.areaMap.length > 5) {
            // splice 方法改变原数组=> areaMap
            maxArr = this.totalData.areaMap.splice(0, 5)
            // 截取前十位 剩余数据相加为其他
            let otherNum = 0;
            this.totalData.areaMap.forEach(el => {
              otherNum = otherNum + el.value
            })
            maxArr.push({ value: otherNum, name: '其他' })
          } else {
            maxArr = this.totalData.areaMap
          }
          // 安置率 环形图
          // this.blankRate(this.totalData.plantNum, this.totalData.allTotal)
          // 类型 饼图
          this.totalData.plantMap = this.totalData.plantMap.filter(item => item.name !== '已安置')
          this.houseType(this.totalData.plantMap)
          // 小区 饼图
          this.apartment(maxArr)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 安置率 环形图
    blankRate (val, total) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("chArt")
      );
      var option = {
        series: [
          {
            type: "pie",
            radius: ["40%", "50%"],
            center: ["50%", "50%"],
            data: [
              {
                name: "安置率",
                value: val,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#356eff", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#9bfcaf", // 100% 处的颜色
                      },
                    ],

                    global: false, // 缺省为 false
                  },
                },
                label: {
                  show: true, //单独显示该数据项
                  formatter: function (obj) {
                    return obj.name + '\n' + obj.percent.toFixed(0) + '%'
                  },
                  color: "#49c3e3", // 100% 处的颜色
                  fontSize: '20',
                  fontWeight: 'bold'
                },
              },
              {
                value: total - val,
                itemStyle: {
                  color: "#ccc",
                },
                label: {
                  emphasis: {
                    show: false,
                  },
                },
              },
            ],
            itemStyle: {
              normal: {},
            },
            label: {
              normal: {
                //默认不显示数据
                show: false,
                position: "center",
              },
              color: "#fff",
            },
          },
        ],
      };
      myChartDrawer.setOption(option);
    },
    // 安置房情况 饼图
    houseType (val) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChart")
      );
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}套 ({d}%)",
        },
        label:{
            color:'#fff'
        },
        series: [
          
           {
            data: val,
            name: "",
            type: "pie",
            radius: ['40%', '60%'],
            center: ["50%", "40%"],
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '90%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} ({d}%)}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 0,
              length2: 0,
              maxSurfaceAngle: 0
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
        ],
      };
      myChartDrawer.setOption(option);
    },
    // 小区占比 饼图
    apartment (val) {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myCharts")
      );
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}套 ({d}%)",
        },
        label:{
            color:'#fff'
        },
        series: [
          {
            data: val,
            name: "",
            type: "pie",
            radius: ['40%', '60%'],
            center: ["50%", "50%"],
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '90%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} ({d}%)}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 20,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
        ],
      };
      myChartDrawer.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
// rgb(156,242,255);
#leftLevel {
  padding: 0.2rem;
  // height: 7.125rem;
  height: 100%;
  min-width: 7rem;
  border-radius: 0.0625rem;
  // overflow: hidden;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
  }
  .title-icon {
    position: relative;
    // width: 6%;
  }
  .text {
    // color: #c3cbde;
    font-size: 0.25rem;
    font-weight: bold;
    color: rgb(156,242,255);
  }
  #parent::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  #parent {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .top_title {
    display: flex;
    .contact_top {
      height: 100%;
      display: flex;
      flex-direction: column;
      .Settled {
        flex: 1;
        display: flex;
        padding: 0.1rem 0 0 0.1rem;
        align-items: center;
        .num {
          text-align: center;
          margin: 0;
          padding: 0;
          width: 100%;
          font-size: 0.2rem;
          font-weight: bold;
          color: rgb(156,242,255);
          span {
            padding: 0 10px;
            color: rgb(156,242,255);
            font-size: 0.35rem;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.assets-info {
  padding: 10px 10px;
  overflow: hidden;
  display: flex;
}
.histogram-info {
  width: 100%;
}
#myChart {
  width: 100%;
  height: 100%;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
.home {
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 22%;
  img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    top: 45%;
    left: 20%;
    font-size: 20px;
    font-weight: bold;
    margin: 0px 5px;
  }
}
.asset-bg {
  width: 30%;
  height: 48%;
  margin-bottom: 10px;
  .num {
    margin: 0;
    padding: 0 0 0 0.3rem;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: rgb(156,242,255);
    margin-bottom: 20px;
    span {
      color: rgb(156,242,255);
      font-size: 40px;
    }
  }
  .desc {
    width: 100%;
    line-height: 100%;
    background: linear-gradient(#2ae9f2, #1471b7);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
}
::v-deep#leftLoops {
  padding: 0.2rem;
  height: 5rem;
  height: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
  }
  .d-flex pt-2 pl-2 {
    height: 5%;
  }
  .text {
    // color: #c3cbde;
    font-size: 0.25rem;
    font-weight: bold;
    color: rgb(156,242,255);
  }

  .color0 {
    color: #ff5722;
  }

  .color1 {
    color: #409eff;
  }
}
.histogram-info {
  height: 95%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .empty {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.4rem;
  }
}
#myCharts {
  width: 100%;
  height: 100%;
}
.myChart {
  position: relative;
  width: 100%;
  display: flex;
  .Settled {
    flex: 1;
    display: flex;
    // padding: 0.1rem 0 0 0.6rem;
    // text-align: center;
    align-items: center;
    .num {
      text-align: center;
      margin: 0;
      padding: 0;
      width: 100%;
      font-size: 0.2rem;
      font-weight: bold;
      color: rgb(156,242,255);
      span {
        padding: 0 10px;
        color: rgb(156,242,255);
       font-size: 0.35rem;
        font-weight: bold;
      }
    }
  }
}
.myChartflex {
  // flex: 1;
}
.chart_Rehousingrate {
  height: 100%;
  width: 100%;
}
</style>

<template>
  <div id="leftLevel">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2" style="height:4%">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <template>
            <span class="fs-xl text mx-2">资产基本信息</span>
          </template>
          <!-- <dv-decoration-1 style="
                width: 1.25rem;
                height: 0.25rem;
                position: relative;
                top: -0.0375rem;
              " /> -->
        </div>
      </div>
      <div id="parent" style="height:96%;">
        <div class="assets-info">
          <div class="asset-bg" @click="onDetail(0)">
            <p class="num">
              {{ assetBasicInfo.estateHouseSets }}
              <span>套</span>
            </p>
            <p class="desc">住宅</p>
          </div>
          <div class="asset-bg" @click="onDetail(1)">
            <p class="num">
              {{ assetBasicInfo.shopUsableArea }}
              <span>平方</span>
            </p>
            <p class="desc">商铺</p>
          </div>
          <div class="asset-bg" @click="onDetail(2)">
            <p class="num">
              {{ assetBasicInfo.officeUsableArea }}
              <span>平方</span>
            </p>
            <p class="desc">办公</p>
          </div>
          <!-- <div class="asset-bg">
            <p class="num">{{assetBasicInfo.parkUsableArea}}<span>个</span></p>
            <p class="desc">泊位</p>
          </div> -->
          <div class="asset-bg" @click="onDetail(3)">
            <p class="num">
              {{ assetBasicInfo.otherUsableArea }}
              <span>平方</span>
            </p>
            <p class="desc">其他</p>
          </div>
        </div>
        <div class="histogram-info">
          <div class="d-flex pt-2 pl-2 flex" style="height:3%;">
            <span style="color: #5cd9e8">
              <icon name="chart-bar"></icon>
            </span>
            <span class="fs-xl text mx-2">镇街住宅总量信息</span>
            <Company class="company" :company="company" />
          </div>
          <div id="myChartleft"></div>
        </div>
        <el-dialog title="详情" :visible.sync="dialogVisible" width="1100px" append-to-body>
          <el-table style="width:100%" :data="totalData" v-loading="loading">
            <el-table-column prop="belongStreet" fixed label="属地镇街" width="100">
              <template slot-scope="scope">
                {{ scope.row.belongStreet || '—' }}
              </template>
            </el-table-column>
            <el-table-column prop="community" label="小区" width="140">
              <template slot-scope="scope">
                {{ scope.row.community || '—' }}
              </template>
            </el-table-column>
            <el-table-column prop="gardens" label="苑名" width="">
              <template slot-scope="scope">
                {{ scope.row.gardens || '—' }}
              </template>
            </el-table-column>
            <el-table-column prop="address" label="房源地址" width="200">
              <template slot-scope="scope">
                {{ scope.row.address || '—' }}
              </template>
            </el-table-column>
            <el-table-column prop="buildDepart" label="建设主体" width="160">
              <template slot-scope="scope">
                {{ scope.row.buildDepart || '—' }}
              </template>
            </el-table-column>
            <el-table-column prop="measureArea" label="实测面积" width="">
              <template slot-scope="scope">
                {{ scope.row.measureArea === null ? '—' : scope.row.measureArea + '㎡' }}
              </template>
            </el-table-column>
            <el-table-column prop="houseType" label="房屋性质" width="">
              <template slot-scope="scope">
                {{ scope.row.houseType | houseIs }}
              </template>
            </el-table-column>
            <el-table-column prop="houseUseStatus" label="房屋使用" width="">
              <template slot-scope="scope">
                {{ scope.row.houseUseStatus | houseStatus }}
              </template>
            </el-table-column>
          </el-table>
          <el-row class="row-margin" v-if="totalData.length > 0">
            <el-col :span="24" class="end">
              <el-pagination
                :pager-count="7"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.pageNum"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
              ></el-pagination>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import indexApi from '@/xhr/index'
import Company from '../Company.vue'
import store from '../../store'
import api from '@/xhr/index'
import { houseIs, houseStatus } from '../../filters/park'
import qs from 'qs'
export default {
  name: 'leftLevel',
  props: {
    areaData: {
      type: Object,
      default: () => {}
    }
    // indexData: {
    //   type: Object,
    //   default: () => { },
    // }
  },
  components: {
    Company
  },
  watch: {
    areaData(val) {
      this.areaDataObj = val
    }
  },
  data() {
    return {
      company: '套',
      indexData: {},
      assetBasicInfo: {},
      areaDataObj: {
        areaCode: '410000',
        areaLevel: 'province',
        name: '西湖区',
        areaName: '西湖区'
      },
      config: {
        name: '区块名称',
        value: 40,
        lineDash: [1, 0],
        borderWidth: 2,
        borderGap: -1,
        borderRadius: 2,
        colors: ['#01c4f9', '#c135ff'],
        done: 10,
        all: 20,
        formatter: '' //{value}%
      },
      myChartDrawer: null,
      openModelType: -1,
      dialogVisible: false,
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      loading: false,
      totalData: []
    }
  },
  created() {
    this.getIndexData()
  },
  filters: { houseIs, houseStatus },
  mounted() {
    // this.getIndexData()
    // this.draw();
    let _this = this
    window.addEventListener('resize', function() {
      if (_this.resizeTimer) clearTimeout(_this.resizeTimer)
      _this.resizeTimer = setTimeout(function() {
        _this.myChartDrawer.resize()
      }, 100)
    })
    this.timer = window.setInterval(() => {
      _this.getIndexData()
    }, 60000)
    
    const query = qs.parse(window.location.href.split('?')[1])
    if (query && query.key) {
      store.dispatch('getToken', query.key)
    }
  },
  destroyed() {
    //  销毁定时器
    window.clearInterval(this.timer)
  },
  methods: {
    getIndexData() {
      indexApi.getLeftData().then(res => {
        if (res.data.code === '200') {
          this.indexData = res.data.data
          this.assetBasicInfo = this.indexData.assetBasicInfo
          if (this.indexData.projectPlantInfo) {
            this.indexData.tableData = []
            this.indexData.name = []
            this.indexData.projectPlantInfo.forEach((el, index) => {
              this.indexData.tableData.push(el.value)
              this.indexData.name.push(el.name)
            })
          }
          this.draw()
        }
      })
    },
    draw() {
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(document.getElementById('myChartleft'))
      this.myChartDrawer = myChartDrawer
      // 绘制条形图
      var option = {
        color: ['#3398DB'],
        label: {
          color: '#fff'
        },
        tooltip: {
          show: true,
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
          //  formatter: function (params) { // 鼠标划过提示语
          //       let pna = params.value + '套'
          //       //  (params.value >1000?((params.value)/10000).toFixed(2):((params.value)/10000).toFixed(4))
          //       return pna;
          //     },
        },
        grid: {
          top: '18%',
          left: '3%',
          right: '4%',
          bottom: '4%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.indexData.name,
            axisTick: {
              alignWithLabel: true,
              color: '#fff'
            },
            axisLabel: {
              show: true,

              color: '#FFFFFF',
              fontWeight: 600,
              fontSize: 10
            },
            // 坐标轴颜色
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              // show: false,// 取消刻度线
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }, //
            axisLabel: {
              show: true,
              color: '#FFFFFF',
              fontWeight: 800,
              fontSize: 14
              // formatter : function(value, index) {
              //   if(value != 0)
              //   return (value/10000).toFixed(1) ;
              //   else
              //   return 0;
              //   }
            },
            axisLine: {
              lineStyle: {
                type: 'solid',
                color: '#1C4274',
                width: '1'
              }
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '40%',
            label: {
              // color: 'red',
              show: true,
              position: 'top'
              // formatter: function (params) {
              //   let pna =  ((params.value)/10000).toFixed(1)
              //   return pna;
              // },
            },
            data: this.indexData.tableData,
            itemStyle: {
              color: function(params) {
                var colorlist = ['#1FC9F4', '#1FB9F4', '#1895E5', '#1870E5', '#185AE5', '#1845E5']
                return colorlist[params.dataIndex]
              }
            }
          }
        ]
      }

      myChartDrawer.setOption(option)
    },
    sliderScroll() {
      let scroll = document.getElementsByClassName('scroll-box')
      let parent = document.getElementById('parent')
      let time = setInterval(function() {
        parent.scrollTop++
      }, 100)
      scroll[0].addEventListener('mouseover', function() {
        clearInterval(time)
      })
      scroll[0].addEventListener('mouseout', function() {
        time = setInterval(function() {
          parent.scrollTop++
        }, 100)
      })
    },
    onDetail(index) {
      const val = store.getters.hasDetailPermission
      if (!val) return
      this.openModelType = index
      this.GetTableData()
      this.dialogVisible = true
    },
    GetTableData() {
      var that = this
      const typeEnum = ['HOME', 'SHOP', 'OFFICE', 'OTHER']
      this.loading = true
      const params = {
        houseType: typeEnum[this.openModelType],
        ...this.page
      }
      api
        .getDetail(params)
        .then(res => {
          if (res.data.code === '200') {
            that.totalData = res.data.data.list
            that.totalData = this.totalData.filter(el => el.statues === 'USE')
            that.page.total = res.data.data.total
          }
        })
        .finally(() => {
          that.loading = false
        })
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.GetTableData()
    },
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.GetTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
#leftLevel {
  padding: 0.2rem;
  height: 100%;
  width: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;
    width: 100%;
    border-radius: 0.125rem;
  }

  .text {
    font-size: 0.25rem;
    font-weight: bold;
    color: rgb(156, 242, 255);
  }
  #parent::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .company {
    top: 0.08rem;
  }
  #parent {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
.flex {
  display: flex;
}
.assets-info::after {
  content: '';
  width: 32%;
}
.assets-info {
  padding: 0.2rem 0.2rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 50%;
  width: 100%;
}
.histogram-info {
  height: 50%;
  position: relative;
}
#myChartleft {
  width: 100%;
  height: 100%;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
.asset-bg {
  // box-shadow: #2ae9f2 0px 0px 0.15rem inset;
  border: 0.03rem solid #2ae9f2;
  border-radius: 0.05rem;
  background-color: #1d1e2f;
  width: 32%;
  height: 48%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
  margin-bottom: 0.15rem;

  .num {
    font-size: 0.35rem;
    font-weight: bold;
    color: rgb(156, 242, 255);
    margin-bottom: 20px;
    span {
      color: #808080;
      font-size: 0.2rem;
    }
  }
  .desc {
    width: 100%;
    line-height: 100%;
    background: linear-gradient(#2ae9f2, #1471b7);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
}
</style>

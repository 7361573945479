<template>
  <div id="leftLoops">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">小区商铺总量信息</span>
           <Company class="company" :company='company' />
        </div>
      </div>
      <div class="histogram-info">
        <div id="myChartsloop"></div>
        <p class="empty" v-if="emptyText">暂无信息</p>
      </div>
      <!-- <div class="d-flex jc-center body-box">
                <dv-scroll-board class="leftLoop" :config="config" @click="onCountyRowClick" style="width: 100%;height:4.1rem"/>
            </div> -->
    </div>
  </div>
</template>

<script>
import indexApi from '@/xhr/index'
import Company from '../Company'
// import './Colormatch'
export default {
  // props: ['indexData'],
  data () {
    return {
      company: '万/平方',
      indexData: {},
      countyCheckData: [],
      config: {
        header: ["小区", "出租情况"],
        rowNum: 6, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [60],
        align: ["center"],
        data: [],
      },
      myChartDrawer: null,
      emptyText: false
    };
  },
  components: {
    Company
  },
  created () {
    this.getIndexData()
  },
  mounted () {
    // this.draw();
    let _this = this
    window.addEventListener('resize', function () {
      if (_this.resizeTimer) clearTimeout(_this.resizeTimer);
      _this.resizeTimer = setTimeout(function () {
        _this.myChartDrawer.resize();
      }, 100)
    })
    this.timer = window.setInterval(() => {
      _this.getIndexData()
    }, 60000)
  },
  destroyed () {
    //  销毁定时器
    window.clearInterval(this.timer)
  },
  methods: {
    getIndexData () {
      indexApi.getLeftData().then(res => {
        if (res.data.code === '200') {
          this.indexData = res.data.data
          if (this.indexData.communityShopInfo.length === 0) {
            this.emptyText = true
          } else {
            this.emptyText = false
          }
          this.draw()
        }
      })
    },
    draw () {
      this.indexData.communityShopInfo.sort((a, b) => {
        return b.value - a.value
      })
      let maxArr = []
      if (this.indexData.communityShopInfo.length > 4) {
        // splice 方法改变原数组=> communityArea
        maxArr = this.indexData.communityShopInfo.splice(0, 4)
        // 截取前十位 剩余数据相加为其他
        let otherNum = 0;
        this.indexData.communityShopInfo.forEach(el => {
          otherNum = otherNum + el.value
        })
        maxArr.push({ value: otherNum, name: '其他' })
      } else {
        maxArr = this.indexData.communityShopInfo
      }
      // 实例化echarts对象
      let myChartDrawer = this.$echarts.init(
        document.getElementById("myChartsloop")
      );
      this.myChartDrawer = myChartDrawer
      // 绘制环形 7CCFD3 29D9E8 299DC0 285283
      var option = {
        textStyle: {
          fontFamily: 'KaiTi',
          color: '#ccc'
        },
        tooltip: {
          trigger: "item",
          // formatter: "{a} <br/>{b} : {c} ({d}%)",
          formatter: function (params) { // 鼠标划过提示语
                let pna = params.name +'\t\t\t'+ params.percent+'%' + '\n' + params.value+'平方'
                return pna;
              },
        },
        series: [
          {
            data: maxArr,
            name: "",
            type: "pie",
            radius: ['40%', '65%'],
            center: ["50%", "58%"],
            itemStyle: {
              color: function (params) {
                // var colorlist = ['#DE2425', '#2CF9DC', '#178BF9', '#FDDC55', '#F47D7A', '#2132CD', '#CBD435', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                var colorlist = ['#1989EA', '#156AE4', '#C1BF52', '#C49741', '#4FE0B6', '#44B079', '#6CAE40', '#317DC0', '#FFC170', '#3BB5A9', '#F97571', '#F97571'];
                // var colorlist = ['#CBD435', '#f6eeda', '#f29a8b', '#e06641', '#F47D7A']
                return colorlist[params.dataIndex];
              },
            },
            height: '100%',
            left: 'center',
            width: '100%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            label: {
              color: '#fff',
              alignTo: 'edge',
              // formatter: '{name|{b} {d}%}\n {c} ',
              formatter: function (params) { // 鼠标划过提示语
                let pna = params.name  + '\n' + ((params.value)/10000).toFixed(2) +'('+ params.percent+'%)'
                return pna;
              },
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 20,
              // color: 'red',
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff'
                }
              }
            },
            labelLine: {
              length: 20,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout (params) {
              var isLeft = params.labelRect.x < myChartDrawer.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;

              return {
                labelLinePoints: points
              };
            },
          },
        ],

      };
      myChartDrawer.setOption(option);
    },
    onCountyRowClick (row) {
      // console.log(row);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#leftLoops {
  padding: 0.2rem;
  height: 5rem;
  height: 100%;
  border-radius: 0.0625rem;
  // overflow: auto;
  .bg-color-black {
    // height: 4.2rem;
    height: 100%;
    border-radius: 0.125rem;
  }
  .d-flex pt-2 pl-2 {
    height: 5%;
  }
  .text {
    font-size: 0.25rem;
    font-weight: bold;
    color: rgb(156,242,255);
  }

  .color0 {
    color: #ff5722;
  }

  .color1 {
    color: #409eff;
  }
}
.histogram-info {
  position: relative;
  height: 95%;
}
#myChartsloop {
  width: 90%;
  height: 100%;
  min-height: 220px;
  clear: both;
  box-sizing: border-box;
  margin: 0px auto;
}
.empty {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.4rem;
}
</style>

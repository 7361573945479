<template>
  <div class="login-page">
     <div class="leftLevel">
      <dv-border-box-12 style="height: 60%">
         <leftLevel></leftLevel>
      </dv-border-box-12>
      <dv-border-box-12 style="height: 40%">
          <leftLoops></leftLoops>
      </dv-border-box-12>
    </div>
    <div class="arrange">
      <arrange></arrange>
    </div>
    <div class="blueleftTop">
      <dv-border-box-12 style="height: 100%">
        <div style="height: 40%">
            <rent-table :infoData='rentData.curLeaseInfo'></rent-table>
        </div>
       <div  style="height: 60%">
         <blueleftTop></blueleftTop>
       </div>
      </dv-border-box-12>
    </div>
    
  </div>
</template>

<script>
import arrange from './arrangeleftTop.vue'
import blueleftTop from './blueleftTop.vue'
import leftLevel from './leftLevel.vue'
import leftLoops from './leftLoops.vue'
import rentTable from './rentTable.vue'
import rentApi from '@/xhr/rent'
export default {
  data () {
    return {
     rentData: [],
    }
  },
  components: {
    arrange,
    blueleftTop,
    leftLevel,
    leftLoops,
    rentTable
  },


  mounted () {
    this.getRentData()
    this.rentTimer = setInterval(() => {
          this.getRentData()
    }, 10000)

  },

  methods: {
     getRentData () {
      rentApi.rentInformation().then(res => {
        if (res.data.code === '200') {
          let data = res.data.data
          this.rentData = res.data.data
          // 租赁概况
          let totalData = [] //总量
          let rentedData = []//已出租
          let sameRate = [] //同比
          let chainRate = []//环比
          data.curLeaseInfo.forEach(el => {
            if (el.name === 'BLUE_APARTMENT' || el.name === 'SHOP') {
              // 蓝领和办公 单位是间
              el.totalCount === null ? totalData.push(0) : totalData.push(el.totalCount)//总间数
              el.rentedCount === null ? rentedData.push(0) : rentedData.push(el.rentedCount)//已出租
            } else {
              // 办公和其他 单位是平方
              el.totalArea === null ? totalData.push(0) : totalData.push(el.totalArea)//总面积
              el.rentedArea === null ? rentedData.push(0) : rentedData.push(el.rentedArea)//已出租
            }
            sameRate.push(el.sameRate)
            chainRate.push(el.chainRate)
          })
          let rentHouse = []
          let houseMounth = []
          data.apartCurrYearMonthRentedCountInfo.forEach(el => {
            rentHouse.push(el.value)
            houseMounth.push(el.month)
          })
          this.$refs.rentHouse.draw(rentHouse, houseMounth)
          // 办公去年
          let rentOffice = []
          let officeMounth = []
          data.officeCurrYearMonthRentedCountInfo.forEach(el => {
            rentOffice.push(el.value)
            officeMounth.push(el.month)
          })
          this.$refs.rentOffice.draw(rentOffice, officeMounth)
          // 商铺去年
          let rentShop = []
          let shopMounth = []
          data.shopCurrYearMonthRentedCountInfo.forEach(el => {
            rentShop.push(el.value)
            shopMounth.push(el.month)
          })
          this.$refs.rentShop.draw(rentShop, shopMounth)
          // 其他去年
          let rentOther = []
          let otherMounth = []
          data.otherCurrYearMonthRentedCountInfo.forEach(el => {
            rentOther.push(el.value)
            otherMounth.push(el.month)
          })
          // this.$refs.rentOther.draw(rentOther, otherMounth)
          //饼图 小区公寓
          // 处理饼图数据 从大到小排序 取前十个展示
          data.apartRentedInfo.sort((a, b) => {
            return b.value - a.value
          })
          let maxArr = []
          if (data.apartRentedInfo.length > 5) {
            // splice 方法改变原数组=> communityArea
            maxArr = data.apartRentedInfo.splice(0, 5)
            // 截取前十位 剩余数据相加为其他
            let otherNum = 0;
            data.apartRentedInfo.forEach(el => {
              otherNum = otherNum + el.value
            })
            maxArr.push({ value: otherNum, name: '其他' })
          } else {
            maxArr = data.apartRentedInfo
          }
          this.$refs.rentPies.drawHome(maxArr)
          //饼图 小区商铺
          // 处理饼图数据 从大到小排序 取前十个展示
          data.shopRentedInfo.sort((a, b) => {
            return b.value - a.value
          })
          let maxShop = []
          if (data.shopRentedInfo.length > 5) {
            // splice 方法改变原数组=> communityArea
            maxShop = data.shopRentedInfo.splice(0, 5)
            // 截取前十位 剩余数据相加为其他
            let otherShopNum = 0;
            data.shopRentedInfo.forEach(el => {
              otherShopNum = otherShopNum + el.value
            })
            maxShop.push({ value: otherShopNum, name: '其他' })
          } else {
            maxShop = data.shopRentedInfo
          }
          this.$refs.rentPies.drawShop(maxShop)
        } else {
          this.$message.error(res.data.message)
        }
      })

    },

  }
}
</script>

<style lang="scss">
.login-page {
  background-image: url(../../assets/pageBg.png);
  // background-image: linear-gradient(#39a1e7, #fff);
  width: 100vw;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.arrange {
  height: 100vh;
  width: 33vw;
  // background-color: rgb(202, 139, 139);
  color: #fff;
}
.blueleftTop{
  height: 100vh;
  width: 33vw;
  // background-color: rgb(202, 139, 139);
  color: #fff;
}
.leftLevel{
  height: 100vh;
  flex: 1;
  // background-color: rgb(202, 139, 139);
  color: #fff;
}
.leftLoops{
   height: 100vh;
  width: 33vw;
  // background-color: rgb(202, 139, 139);
  color: #fff;
}


</style>

export const houseIs = function(val) {
  const dict = {
    HOME: '住宅',
    SHOP: '商铺',
    OFFICE: '办公',
    OTHER: '其他'
  }
  return dict[val] || '—'
}
export const leaseStatus = function(val) {
  const dict = {
    RENT: '已出租',
    FREEZE: '已冻结',
    BLANK: '空置',
    DELETE: '已删除',
    NOINFORM: '—'
  }
  return dict[val] || '—'
}
export const houseStatus = function(val) {
  const dict = {
    BLUECOLLAR: '蓝领',
    ARRANGE: '空置',
    PLANTED: '已安置',
    ALLOT: '预留安置',
    BORROW: '借用',
    OTHER: '其他',
    FHOUSEG: '—'
  }
  return dict[val] || '—'
}

/**
 * @description 停车场性质
 * @param {*} val
 */
export const parkType = function(val) {
  const dict = {
    TEMPORARYPARK: '临时停车场',
    ALONEPUBLICPARK: '单独建设公共停车场',
    PUBLICBUILDPARK: '公共建筑配建停车场'
  }
  return dict[val] || val
}

/**
 * @description 停车场状态
 * @param {*} val
 */
export const parkStatus = function(val) {
  const dict = {
    NOTOPEN: '未开放',
    OPEN: '已开放',
    OPERATION: '委托运营',
    SUSPEND: '暂停运营'
  }
  return dict[val] || val
}

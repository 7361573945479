
<template>
  <div class="positonleft">
      单位:（{{company}}）
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ['company'],
};
</script>

<style scoped>
    .positonleft{
    color: white;
    font-size: .2rem;
    font-weight: 600;
    /* // float: right; */
    position: absolute;
    padding-right:0.3rem ;
    right: 0.1rem;
    top: .3rem;
    /* // top: 0.3rem; */
    }
</style>

